
import { authenticated, role } from '../stores/auth'
import { writable, get } from 'svelte/store'
import { wrap } from 'svelte-spa-router/wrap'
import { push, RouteDetail, } from 'svelte-spa-router'
import SignInView from '../views/SignIn.svelte'
import WelcomeView from '../views/Welcome.svelte'
import NotFound from '../views/404.svelte'

const notFoundError = writable(false)

const routes = {

  '/': wrap({
    component: WelcomeView,
    userData: { showNavHeader: true },
    conditions: [ requiresAuth  ]
  }),

  '/welcome': wrap({ component: WelcomeView, conditions: [ redirectToRoot ]}),

  '/sign-in': SignInView,

  '/forgot-password': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-forgot-password' */'../views/Forgot.svelte')
  }),

  '/unsubscribe': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-unsubscribe' */'../views/Unsubscribe.svelte')
  }),

  '/admin': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-admin' */'../views/Admin.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/admin/:username': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-admin-user' */'../views/AdminUser.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/buttons': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-buttons' */'../views/Buttons.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/territories': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-territories' */'../views/Territories.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),
  
  '/metrics': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-metrics' */'../views/Metrics.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/email-blast': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-email-blast' */'../views/EmailBlast.svelte'),
    userData: { showNavHeader: true, requiresAuth: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/message-editor': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-message-editor' */'../views/MessageEditor.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth, requiresAdmin ]
  }),

  '/profile': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-profile' */'../views/Profile.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth ]
  }),

  '/org': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-org' */'../views/Org.svelte'),
    userData: { showNavHeader: true },
    conditions: [ requiresAuth ]
  }),

  '/register': wrap({
    asyncComponent: () => import(/* webpackChunkName: 'beta-view-register' */'../views/Register.svelte'),
    userData: { showNavHeader: true }
  }),

  '*': NotFound
}

function redirectToRoot(): boolean {
  push('/')
  return true
}

function requiresAuth(detail: RouteDetail): boolean {
  if ( !get(authenticated) ) {
    push('/sign-in')    
    return false
  } else {
    return true
  }
}


function requiresAdmin(detail: RouteDetail): boolean {
  if (get(role) === 'admin') {
    return true
  } else {
    // set to not found to load 404 component
    notFoundError.set(true)
    return false
  }
}

export default routes
export { notFoundError }
