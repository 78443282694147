<script lang="ts">
  const title = 'Cue Admin Sign-In'
  import { signIn } from '../stores/auth'
  import { link, push } from 'svelte-spa-router'
  import AlertView from '../components/Alert.svelte'
  import type { AlertViewInterface } from '../components/Alert.svelte'
  import isEmpty from 'lodash.isempty'

  let alertView: AlertViewInterface
  let orgId: string
  let password: string
  let emailAddress: string
  let submitLoading = false

  async function submitForm(e: Event): Promise<void> {
    submitLoading = true
    try {

      alertView.remove()

      if ( isEmpty(orgId) ) {
        alertView.show('danger','Organization ID cannot be empty')
        submitLoading = false
        return 
      } else if ( isEmpty(emailAddress) ) {
        alertView.show('danger','Email cannot be empty')
        submitLoading = false
        return 
      } else if ( isEmpty(password) ) {
        alertView.show('danger','Password cannot be empty')
        submitLoading = false
        return 
      }    
        
      // sign in
      await signIn(orgId, emailAddress, password)

      // go to welcome page
      push('/welcome')

    } catch(err) {
      if (err.code === 'PasswordResetRequiredException') {
        push('/forgot-password')
      } else if (err.code === 'OrganizationNotFound' || err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        alertView.show('danger', 'Could not sign-in with provided credentials.');
      } else {
        alertView.show('danger', 'Could not sign-in – unexpected problem.');
        console.error(err);
      }
    }
    submitLoading = false
  }
</script>

<svelte:head>
	<title>{title}</title>
</svelte:head>

<div id="sign-in-page" class="container">
  <h1>{title}</h1>
  <AlertView bind:this={alertView} />
  <form>
    <div class="form-group">
      <label for="orgInput">Organization ID</label>
      <!-- 
        the id attribute for the organization field has been left in here so
        that password managers will continue to work. 
      -->
      <input 
        id="orgInput" 
        type="text" 
        class="form-control" 
        placeholder="Organization ID" 
        bind:value={orgId}
      >
    </div>
    <div class="form-group">
      <label for="emailInput">Email</label>
      <input 
        type="email" 
        class="form-control" 
        placeholder="Email" 
        autocomplete="email"
        bind:value={emailAddress}
        >
    </div>
    <div class="form-group">
      <label for="passwordInput">Password</label>
      <input 
        type="password" 
        class="form-control" 
        placeholder="Password" 
        autocomplete="current-password"
        bind:value={password}
      >
      <a href="/forgot-password" use:link>Forgot password</a>
    </div>
    <button 
      class="btn btn-primary" 
      type="submit" 
      disabled={submitLoading}
      on:click|preventDefault={submitForm}
    >{#if submitLoading}<div class="button-loader"></div>{/if}
    <span class={submitLoading ? "invisible" : ""}>Submit</span></button>
  </form>
</div>