type CueErrorCode = 'OrganizationNotFound' | 'UnknownError'

export default class CueError extends Error {

  code: CueErrorCode
  response: Response

  constructor(message: string, code: CueErrorCode, response?: Response) {
    super(message)
    this.name = "Exception"
    this.code = code
    this.response = response
  }
}
