
import { get } from 'svelte/store'
import { orgInfo, apiBaseURL } from '../stores/auth'
import { Auth as Amplify } from 'aws-amplify'
import { validateEmail } from '../scripts/helpers'
import CueError from '../scripts/error'

const orgIdEndpoint = 'https://pxxig8ib68.execute-api.us-east-1.amazonaws.com/production/org/info';
const orgIdKey = '87jjZpaE4I1EE9Lv9AMn5a7KoUfVrWnmareFmPBM';

const resources = {
  deleteButton:         'button/delete',
  getButtons:           'button/getbyrep',
  getAllButtons:        'button/getall',
  transferButton:       'button/transfer',
  updateCell:           'user/updatecell',
  getMessageTemplates:  'messaging/getmessagetemplates',
  postMessageTemplate:  'messaging/postmessagetemplate',
  sampleMessageTemplate:'messaging/sendmessagetemplatesample',
  emailBlast:           'messaging/emailblast',
  getMetrics:           'metrics/getmetrics',

  inviteUser:           'user/invite',
  resendInvite:         'user/resend-invite',
  registerUser:         'user/register',
  getPermissions:       'user/permissions',
  getUsers:             'user/getusers',
  getUser:              'user/getuserinfobyusername',
  editUser:             'user/edit',
  disableUser:          'user/disable',
  unsubscribe:          'user/unsubscribe',

  getTerritories:       'territories/get',
  updateTerritory:      'territories/update',
};

async function getToken(){
  if(!get(apiBaseURL)){
    
    console.log(get(orgInfo).apiBaseURL)
    apiBaseURL.set( get(orgInfo).apiBaseURL )
  }

  //get JWT
  try{
    const session = await Amplify.currentSession();
    return session.getIdToken().getJwtToken()
  } catch(err) {
    console.error(err);
    throw err
  }
}

function getOrgInfo(orgId: string): Promise<any> {

  if (!orgId || orgId === '') throw 'orgId is blank';

  const headers = {
    'X-Api-Key': orgIdKey
  };
  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: 'GET',
      url: `${orgIdEndpoint}?orgid=${orgId}`,
      success: resolve,
      error: (response: any) => {
        if (response.status === 404) {
          reject( new CueError('Organization was not found', 'OrganizationNotFound', response) )
        } else {
          reject( new CueError('Unknown error', 'UnknownError', response) )
        }
      },
      headers: headers
    });
  });
}

async function invite(
    username: string, 
    orgId: string, 
    role: UserRoleType, 
    territory:string, 
    permission_add: boolean, 
    permission_delete: boolean, 
    permission_read: boolean
  ): Promise<void> {

  let jwt = await getToken();
  const data = {
    'username' : username,
    'role' : role,
    'territory' : territory,
    'permission_add' : permission_add.toString(),
    'permission_delete' : permission_delete.toString(),
    'permission_read' : permission_read.toString(),
    'orgId' : orgId
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL) + resources.inviteUser,
      dataType: 'json',
      data: JSON.stringify(data),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function resendInvite(username: string): Promise<void> {
  let jwt = await getToken();
  const data = {
    'username' : username,
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL) + resources.resendInvite,
      dataType: 'json',
      data: JSON.stringify(data),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt
      }
    });
  })
}

export async function register(
    apiBaseURL: string, 
    token: string, 
    first_name: string, 
    last_name: string, 
    password: string, 
    cell: string
  ): Promise<void> {
    
  const data = {
    t: token,
    first_name,
    last_name,
    password,
    cell
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: apiBaseURL + resources.registerUser,
      dataType: 'json',
      data: JSON.stringify(data),
      success: resolve,
      error: (response) => reject(
        new CueError(
          response.responseJSON.error.message, 
          response.responseJSON.error.code, 
          response
        ) 
      )
    });
  })
}

async function getPermissions(): Promise<any> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getPermissions,
      dataType: 'json',
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function transferButton(dashbuttonid:  string[], toRep: string): Promise<void> {
  let jwt = await getToken();

  const data = {
    'dashbuttonid' : dashbuttonid,
    'torep' : toRep
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL) + resources.transferButton,
      dataType: 'json',
      data: JSON.stringify(data),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function editUser(
    repusername: string, 
    role: UserRoleType, 
    territory: string,
    permission_add: boolean,
    permission_delete: boolean
  ): Promise<void> {

  let jwt = await getToken();
  
  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL)  + resources.editUser,
      dataType: 'json',
      data: JSON.stringify({
        'username':repusername,
        'role':role,
        'territory':territory,
        'permissionAdd':permission_add.toString(),
        'permissionDelete':permission_delete.toString()
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  });
}

async function disableUser(repusername: string, isDisabled: boolean) {
  let jwt = await getToken();

  const disabledValue = isDisabled.toString()

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL) + resources.disableUser,
      dataType: 'json',
      data: JSON.stringify({
        'username': repusername,
        'disable': disabledValue
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  });
}

async function unsubscribe(
    apiBaseURL: string, 
    apiKey: string, 
    email: string
  ): Promise<void> {

  return new Promise((resolve, reject) => {    
    jQuery.ajax({
      type:'POST',
      url: apiBaseURL + resources.unsubscribe,
      dataType: 'json',
      data: JSON.stringify({
        'email': email
      }),
      success: resolve,
      error: reject,
      headers: {
        'X-Api-Key': apiKey,
      }
    });
  });
}

async function deleteButton(dashbuttonids: string[]): Promise<void> {
  let jwt = await getToken();

  const data = {
    'dashbuttonid' : dashbuttonids
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'POST',
      url: get(apiBaseURL) + resources.deleteButton,
      dataType: 'json',
      data: JSON.stringify(data),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function updateCell(): Promise<JSON> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.updateCell,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt
      }
    })
  })
}

async function emailBlast(
  dashbuttonids: string[],
  subject: string,
  html: string,
  plain: string
): Promise<JSON> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: "POST",
      dataType: "json",
      url: get(apiBaseURL) + resources.emailBlast,
      data: JSON.stringify({ dashbuttonids, subject, html, plain }),
      success: resolve,
      error: reject,
      headers: {
        Authorization: jwt,
      },
    });
  });
}

async function getMetrics(params: {}): Promise<JSON[]> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getMetrics,
      data: params,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

type EditUserStringBooleanType = 'true' | 'false'
type UserRoleType = 'admin' | 'user'
type UserStatusType = 'ACTIVE' | 'INVITED'

interface GetUserDataInterface {
  repusername: string,
  created: EditUserStringBooleanType,
  disabled: EditUserStringBooleanType,
  permission_add: EditUserStringBooleanType,
  permission_delete: EditUserStringBooleanType,
  permission_read: EditUserStringBooleanType,
  role: UserRoleType,
  status: UserStatusType,
  territory: string
}

 async function getUser(username: string): Promise<GetUserDataInterface> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getUser,
      data: {repusername: username},
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function getUsers(): Promise<GetUserDataInterface[]> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getUsers,
      dataType: 'json',
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function getButtonsByRep(repusername): Promise<any> {
  let jwt = await getToken();

  const data = {
    'repusername' : repusername
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getButtons,
      data: data,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function getButtonsByOriginalRep(originalrep: string): Promise<any> {
  let jwt = await getToken();

  const data = {
    'originalrep' : originalrep
  };

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getButtons,
      data: data,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  });
}

async function getAllButtons(): Promise<any> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type:'GET',
      url: get(apiBaseURL) + resources.getAllButtons,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function getUnassignedButtons(): Promise<any> {
  return getButtonsByRep('Unassigned');
}




async function getMessageTemplates(): Promise<{}> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: 'GET',
      url: get(apiBaseURL) + resources.getMessageTemplates,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  }) 
}

async function postMessageTemplate(template: string, message: string): Promise<void> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.postMessageTemplate,
      dataType: 'json',
      data: JSON.stringify({
        'template': template,
        'message': message
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function sampleMessageTemplate(recipient: string, template: string): Promise<void> {
  let jwt = await getToken();

  return new Promise((resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.sampleMessageTemplate,
      dataType: 'json',
      data: JSON.stringify({
        'recipient': recipient,
        'template': template,
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
 
}

async function getTerritories(): Promise<any[]> {
  let jwt = await getToken();

  return new Promise( (resolve, reject) => {
    jQuery.ajax({
      type: 'GET',
      url: get(apiBaseURL) + resources.getTerritories,
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

async function addTerritory(territory: string, manageremail: string) {
  let jwt = await getToken();

  return new Promise( (resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.updateTerritory,
      dataType: 'json',
      data: JSON.stringify({
        'operation': 'add',
        'territory': territory,
        'manageremail': manageremail,
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
        'x-api-resource-version': '2'
      }
    });
  })
}

async function updateTerritory(
    previousTerritory: string, 
    territory: string, 
    manageremail: string) 
  {

  let jwt = await getToken();

  return new Promise( (resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.updateTerritory,
      dataType: 'json',
      data: JSON.stringify({
        'operation': 'update',
        'previousTerritory': previousTerritory,
        'territory': territory,
        'manageremail': manageremail,
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
        'x-api-resource-version': '2'
      }
    });
  })
}

async function deleteTerritory(territory: string): Promise<void> {
  let jwt = await getToken();

  return new Promise( (resolve, reject) => {
    jQuery.ajax({
      type: 'POST',
      url: get(apiBaseURL) + resources.updateTerritory,
      dataType: 'json',
      data: JSON.stringify({
        'operation': 'delete',
        'territory': territory,
      }),
      success: resolve,
      error: reject,
      headers: {
        'Authorization':jwt,
      }
    });
  })
}

export {
  getOrgInfo,
  invite,
  resendInvite,
  transferButton,
  editUser,
  disableUser,
  unsubscribe,
  deleteButton,
  getPermissions,
  updateCell,
  emailBlast,
  getMetrics,
  getUser,
  getUsers,
  getButtonsByRep,
  getButtonsByOriginalRep,
  getAllButtons,
  getUnassignedButtons,
  getMessageTemplates,
  postMessageTemplate,
  sampleMessageTemplate,
  getTerritories,
  addTerritory,
  updateTerritory,
  deleteTerritory
}

export type {
  GetUserDataInterface,
  UserRoleType,
  EditUserStringBooleanType
}
