import App from './App.svelte'
import { orgId, signOut, signInWithExistingSession } from './stores/auth'
// import jQuery from "jquery";

// allow bootstrap be able to find jquery
// declare global {
//   interface Window {
//     $: any,
//     jQuery: any
//   }
// }
// window.$ = window.jQuery = jQuery;


const app = (async () => {

  await signInWithExistingSession()
  return new App({
    target: document.body,
  });

})()

export default app;