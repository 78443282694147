<script lang="ts" context="module">
  import type { SvelteComponentTyped } from "svelte/internal"
  
  type StatusType = 'primary' | 'success' | 'info' | 'warning' | 'danger'

  export interface AlertViewInterface extends SvelteComponentTyped {
    show(status: StatusType, text: string): void,
    remove(): void;
  }
  
</script>

<script lang="ts">

  let message: string = null
  let type: string = 'success'
  let typeClassName: string
  let roleType: string

  $: {
    switch(type) {
      case 'success':
        typeClassName = 'alert-success'
        roleType = 'status'
        break;

      case 'info':
        typeClassName = 'alert-info'
        roleType = 'status'
        break;

      case 'warning':
        typeClassName = 'alert-warning'
        roleType = 'alert'
        break;

      case 'danger':
        typeClassName = 'alert-danger'
        roleType = 'alert'
        break;

      default:
       typeClassName = 'alert-success'
       roleType = 'status'
    }
  }

  export function show(t: string, m:string): void {
    type = t
    message = m
  }

  export function remove(): void {
    type = 'primary'
    message = null
  }

</script>

{#if message !== null }
<div id="alertview" class="alert { typeClassName }" role={ roleType }>
  <button type="button" class="close" on:click={ remove }>
    <span>&times;</span>
  </button>
  {@html message }
</div>
{/if}