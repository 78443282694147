import { getPermissions, getOrgInfo } from '../api'
import { Auth as Amplify } from 'aws-amplify'
import type {CognitoUser} from '@aws-sdk/types/dist/es'
import { writable, Writable, get } from 'svelte/store'

interface AmplifyConfigInterface {
  region: 'us-east-1',
  userPoolId: string
  userPoolWebClientId: string
  authenticationFlowType: 'USER_PASSWORD_AUTH'
}

const defaultConfig: AmplifyConfigInterface = {
  region: null,
  userPoolId: null,
  userPoolWebClientId: null,
  authenticationFlowType: null
}

const orgId: Writable<string> = writable(null)
const orgInfo: Writable<any> = writable(null)
const username: Writable<string> = writable(null)
const firstName: Writable<string> = writable(null)
const lastName: Writable<string> = writable(null)
const phoneNumber: Writable<string> = writable(null)
const role: Writable<string> = writable(null)
const authenticated: Writable<boolean> = writable(false)
const cognitoUser: Writable<CognitoUser> | null = writable(null)
const config: Writable<AmplifyConfigInterface> = writable(defaultConfig)
const apiBaseURL: Writable<string> = writable(null)

const signIn = async function(orgIdInput: string, emailAddressInput: string, passwordInput: string): Promise<void> {

  let org: any

  try {
    await signOut()

    org = await getOrgInfo(orgIdInput)
    
    // save data
    config.set( createAmplifyConfig(org.poolId, org.clientId_web) )

    // configure Amplify from that data
    Amplify.configure( get(config) );

    // attempt signIn
    const userResponse = await Amplify.signIn(emailAddressInput.toLowerCase(), passwordInput)

    // set values
    cognitoUser.set(userResponse)

    orgId.set(orgIdInput)
    window.localStorage.setItem('orgId', orgIdInput)

    orgInfo.set(org)
    window.localStorage.setItem('orgInfo', JSON.stringify(org))

    setUserAttributes(userResponse.attributes)

    const userRecord = await getPermissions()
    role.set(userRecord.role)
    
    authenticated.set(true)

  } catch (e) {
    throw e
  }
  
}

const signInWithExistingSession = async function(): Promise<void> {
  try {
    
    // load details from localStorage
    const orgIdLocalStorage: string = window.localStorage.getItem('orgId')
    const orgInfoLocalStorage: any = JSON.parse(
      window.localStorage.getItem('orgInfo')
    )
    
    if (orgInfoLocalStorage && orgInfoLocalStorage.poolId && orgInfoLocalStorage.clientId_web) {

      // configure Amplify
      Amplify.configure(
        createAmplifyConfig(
          orgInfoLocalStorage.poolId, 
          orgInfoLocalStorage.clientId_web
        )    
      );

      orgId.set(orgIdLocalStorage)
      orgInfo.set(orgInfoLocalStorage)

      const user = await Amplify.currentAuthenticatedUser();
      cognitoUser.set(user)
      setUserAttributes(user.attributes)

      const userRecord = await getPermissions()
      role.set(userRecord.role)

      authenticated.set(true)    
    }
    
  } catch(e) {
    console.log('Could not sign-in with existing session', e)
  }
}

const signOut = async function(): Promise<void> {
  try {
    await Amplify.signOut()
    orgId.set(null)
    orgInfo.set(defaultConfig)
    cognitoUser.set(null)
    clearUserAttributes()
    authenticated.set(false)
    apiBaseURL.set(null)
    window.localStorage.clear();
  } catch (e) {
    console.log('failed to sign out', e)
    throw e
  }
}

const createAmplifyConfig =
  function(poolId: string, clientId_web: string): AmplifyConfigInterface {
    return {
      region: 'us-east-1',
      userPoolId: poolId,
      userPoolWebClientId: clientId_web,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  }

const setUserAttributes = function(attributes: any): void {
  username.set(attributes.email)
  firstName.set(attributes.family_name)
  lastName.set(attributes.given_name)
  phoneNumber.set(attributes.phone_number)
}

const clearUserAttributes = function(): void {
  username.set(null)
  firstName.set(null)
  lastName.set(null)
  phoneNumber.set(null)
}


export { 
  orgId, 
  orgInfo,
  cognitoUser, 
  username, 
  firstName, 
  lastName, 
  phoneNumber, 
  role,
  authenticated, 
  apiBaseURL,
  signIn, 
  signInWithExistingSession,
  signOut,
  createAmplifyConfig
}

export type {
  AmplifyConfigInterface
}