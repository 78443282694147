<script type="ts">
  const title = 'Welcome to Cue'
  import { orgId, username } from '../stores/auth'

</script>

<svelte:head>
	<title>{title} • Cue Admin</title>
</svelte:head>

<div id="welcome-page" class="container">

  <h1>{title}</h1>

  You now have a Cue account, and can set up Cue buttons via the Cue Setup app.

  <h2>Getting Started</h2>
  <ol>
    <li><a href="https://apps.apple.com/us/app/cue-setup-by-thingee/id1453760276">Download Cue Setup from the App Store</a></li>
    <li>
      Open Cue Setup and sign in with your credentials:
      <ul>
        <li>Organization ID: <span class="credential-span" id="credential-orgid">{$orgId}</span></li>
        <li>Username: <span class="credential-span" id="credential-username">{$username}</span></li>
        <li>Password: (not shown)</li>
      </ul>
    </li>
    <li>Need help? <a href="mailto:cue-support@thingee.com">Contact Us</a></li>
  </ol>
</div>