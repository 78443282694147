<script lang="ts">
  import Router, { location, link, push } from 'svelte-spa-router'
  import active from 'svelte-spa-router/active'
  import routes, { notFoundError } from './router'
  import NotFound from './views/404.svelte'
  import { authenticated, orgInfo, username, signOut, role } from './stores/auth'

  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'datatables.net-bs/css/dataTables.bootstrap.css';
  import 'datatables.net-select-bs/css/select.bootstrap.css';
  import './styles/main.css';

  import cueLogo from './assets/cue-logo@2x.png'

  const year = new Date().getFullYear();
  
  const navigationLinks = [
    { path: '/admin',          name: 'Admin',          admin: true},
    { path: '/buttons',        name: 'All Buttons',    admin: true },
    { path: '/territories',    name: 'Territories',    admin: true },
    { path: '/metrics',        name: 'Metrics',        admin: true },
    //{ path: '/email-blast',    name: 'Email Blast',  admin: true },
    { path: '/message-editor', name: 'Message Editor', admin: true },
    { path: '/profile',        name: 'My Profile',        admin: false }
  ]
  
  $: navigationLinksToShow = navigationLinks.filter( route => {
      return (
        $role === 'admin' || ($role === 'user' && route.admin === false)
      )
    })

  /* frame busting */
  if (self == top) {
    document.getElementsByTagName('body')[0].style.display = 'block';
  } else {
    top.location = self.location;
  }
  
  let showHeader: boolean 
  function routeLoading(event: any): void {
    
    // reset not found on every route change
    $notFoundError = false

    if (event.detail.userData?.hasOwnProperty('showNavHeader')) {
      showHeader = event.detail.userData?.showNavHeader
    } else { 
      showHeader = false
    }
  }

  function signOutUser(event: Event): void {
    try {
      signOut()
      push('/sign-in')
    } catch(e) {
      console.log(e)
    }
  }

  // work around for broken anchors
  function skipToMain(event: Event): void {
    try {
      let main = jQuery('div#main-content.container');
      main.attr('tabindex', '0')
      main.focus();
      main.attr('tabindex', '-1')
    } catch(e) {
      console.log(e)
    }
  }

</script>

<a href="#main-content" class="sr-only sr-only-focusable" on:click|preventDefault={skipToMain}>Skip to main content</a>

{#if $notFoundError }
  <NotFound />
{:else}

  { #if showHeader }
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-links" aria-expanded="false">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="sr-only">Toggle navigation menu</span>
        </button>

        <a href="/" class="navbar__logo-link" use:link>
          <img alt="Cue" src="{ cueLogo }" class="navbar-brand__logo">
        </a>      
        
      </div>
      <div class="collapse navbar-collapse" id="navbar-links">
        <ul class="nav navbar-nav">

          { #if $authenticated }
            { #each navigationLinksToShow as route }
              <li use:active={{ path: new RegExp(`^${route.path}.*`) }}>
                <a href={route.path} class="navbar-link" use:link>{route.name}</a>
              </li>
            { /each }
          { /if }

          { #if $location === '/register' }
          <li use:active={{ path: new RegExp(`^\/register.*`) }}>
            <a href="/register" class="navbar-link" use:link>Register</a>
          </li>
          { /if }

        </ul>

        <ul class="nav navbar-nav navbar-right">
          { #if $authenticated }
          <li>
            {#if $username}<p class="navbar-text" id="signedInText">Signed in as {$username}.</p>{/if}
          </li>
          <li>
            <button type="button" id="signOutButton" class="btn btn-default navbar-btn" on:click|preventDefault={signOutUser}>Sign out</button>
          </li>
          { /if }
        </ul>
      </div>
    </div>
  </nav>
  { /if }

  <div id="main-content" class="container" role="main">
    <Router {routes} on:routeLoading={routeLoading} />
  </div>

  { #if showHeader }
  <nav class="navbar navbar-default navbar-fixed-bottom">
    <div class="container">
      <div class="navbar-header">
      </div>
      <p class="navbar-text">&copy; {year} <a href="https://thingee.com">Thingee Corporation</a></p>

      {#if $authenticated && $orgInfo.displayName }
      <ul class="nav navbar-nav hidden-xs" style="float: right">
        <li>
          <p class="navbar-text">Organization: <a href="/org" use:link><span id="orgId">{ $orgInfo.displayName }</span></a></p>
        </li>
      </ul>
      {/if}

    </div>
  </nav>
  { /if }
{/if}
